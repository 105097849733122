@charset "UTF-8";
/*
@File: Axolot Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

*** - Default CSS
*** - Prealoader CSS
*** - Navbar CSS
*** - Main Banner CSS
*** - Features CSS
*** - About CSS
*** - Services CSS
*** - Vision CSS
*** - Fun Facts CSS
*** - Join Now CSS
*** - How It Works CSS
*** - Team CSS
*** - Testimonials CSS
*** - FAQ CSS
*** - Pricing CSS
*** - Partner CSS
*** - Subscribe CSS
*** - Page Title CSS
*** - Contact CSS
*** - Footer Area CSS
*** - Go Top CSS
*** - Router Transitions
*/
/* ==============
    Start Default CSS
==================================== */
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@font-face {
  font-family: 'Conv_Campton-Light';
  src: url("../fonts/Campton-Light.eot");
  src: local("☺"), url("../fonts/Campton-Light.woff") format("woff"), url("../fonts/Campton-Light.ttf") format("truetype"), url("../fonts/Campton-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Campton-SemiBold';
  src: url("../fonts/Campton-SemiBold.eot");
  src: local("☺"), url("../fonts/Campton-SemiBold.woff") format("woff"), url("../fonts/Campton-SemiBold.ttf") format("truetype"), url("../fonts/Campton-SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Campton-Thin';
  src: url("../fonts/Campton-Thin.eot");
  src: local("☺"), url("../fonts/Campton-Thin.woff") format("woff"), url("../fonts/Campton-Thin.ttf") format("truetype"), url("../fonts/Campton-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Campton-Bold';
  src: url("../fonts/Campton-Bold.eot");
  src: local("☺"), url("../fonts/Campton-Bold.woff") format("woff"), url("../fonts/Campton-Bold.ttf") format("truetype"), url("../fonts/Campton-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Campton-MediumItalic';
  src: url("../fonts/Campton-MediumItalic.eot");
  src: local("☺"), url("../fonts/Campton-MediumItalic.woff") format("woff"), url("../fonts/Campton-MediumItalic.ttf") format("truetype"), url("../fonts/Campton-MediumItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Campton-SemiBoldItalic';
  src: url("../fonts/Campton-SemiBoldItalic.eot");
  src: local("☺"), url("../fonts/Campton-SemiBoldItalic.woff") format("woff"), url("../fonts/Campton-SemiBoldItalic.ttf") format("truetype"), url("../fonts/Campton-SemiBoldItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Campton-Medium';
  src: url("../fonts/Campton-Medium.eot");
  src: local("☺"), url("../fonts/Campton-Medium.woff") format("woff"), url("../fonts/Campton-Medium.ttf") format("truetype"), url("../fonts/Campton-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Campton-Black';
  src: url("../fonts/Campton-Black.eot");
  src: local("☺"), url("../fonts/Campton-Black.woff") format("woff"), url("../fonts/Campton-Black.ttf") format("truetype"), url("../fonts/Campton-Black.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  font-size: 15px;
  background: #191919 !important; }

img {
  max-width: 100%; }

.no-padding {
  padding-left: 0px;
  padding-right: 0px; }

.no-padding-bottom {
  padding-bottom: 0px !important; }

.top-padding {
  padding-top: 100px; }

.margin_bottom {
  margin-bottom: 100px; }

.d-table {
  width: 100%;
  height: 100%; }
  .d-table-cell {
    vertical-align: middle; }

a {
  text-decoration: none;
  color: #e54545 !important;
  transition: 0.4s;
  cursor: pointer; }

a:hover {
  text-decoration: none;
  color: #e54545 !important;
  transition: 0.4s;
  cursor: pointer; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #191919; }

p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.8;
  color: #6F8BA4; }

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.mb-30 {
  margin-bottom: 30px; }

.mt-30 {
  margin-top: 30px; }

.mt-100 {
  margin-top: 100px; }

.bg-gray {
  background-color: #edf5ff; }

/* Section Title */
.section-title {
  margin-bottom: 60px;
  text-align: center;
  margin-top: -6px; }
  .section-title span {
    display: block;
    font-size: 16px;
    margin-bottom: 6px;
    color: #506cea; }
  .section-title h3 {
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-bottom: 20px;
    max-width: 520px; }
    .section-title h3::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 75px;
      height: 3px;
      border-radius: 5px;
      right: 0;
      margin: 0 auto;
      background: #506cea; }
  .section-title p {
    max-width: 550px;
    margin: 0 auto; }

/* Owl Carousel Btn */
.owl-theme .owl-nav {
  margin-top: 0; }
  .owl-theme .owl-nav [class*="owl-"] {
    font-size: 17px !important;
    margin: 0 !important;
    padding: 0 !important;
    background: #ffffff !important;
    display: inline-block !important;
    border-radius: 0 !important;
    position: absolute;
    left: 20px;
    color: #506cea !important;
    top: 22%;
    height: 150px;
    opacity: 0;
    overflow: hidden;
    width: 15px;
    line-height: 150px;
    transition: 0.4s; }
    .owl-theme .owl-nav [class*="owl-"]:hover {
      color: #ffffff !important;
      background: #506cea !important; }
  .owl-theme .owl-nav .owl-next {
    left: auto;
    right: 20px; }

.owl-theme:hover .owl-nav [class*="owl-"] {
  opacity: 1;
  overflow: visible;
  left: 0; }

.owl-theme:hover .owl-nav .owl-next {
  left: auto;
  right: 0; }

/* Btn Button */
.btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 27px;
  position: relative;
  overflow: hidden; }
  .btn::before {
    content: "";
    position: absolute;
    display: block;
    width: 200px;
    height: 100px;
    -webkit-transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    -ms-transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0; }
  .btn:hover::before {
    background: #ffffff;
    -webkit-transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
    -ms-transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
    transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg); }

.btn-primary {
  background-color: #506cea;
  border-color: #506cea;
  color: #ffffff; }
  .btn-primary.focus, .btn-primary:focus {
    box-shadow: none; }
  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none; }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #506cea;
    border-color: #506cea; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #506cea;
    border-color: #506cea; }
  .btn-primary.disabled, .btn-primary:disabled {
    opacity: 1;
    background-color: #506cea;
    border-color: #506cea; }

.btn-secondary {
  border-radius: 0px;
  letter-spacing: 0px;
  color: #ffffff !important;
  background-color: #e54545;
  border-color: #e54545; }
  .btn-secondary span {
    padding-left: 10px; }
  .btn-secondary:hover {
    color: #ffffff !important;
    background-color: #e54545;
    border-color: #e54545; }
  .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none; }
  .btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none; }
  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #e54545;
    border-color: #e54545; }

/* ==============
    End Default CSS
==================================== */
/* ==============
    Start Navbar CSS
==================================== */
.navbar {
  padding: 20px 0; }
  .navbar.is-sticky {
    background: #ffffff;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown; }

.bg-light {
  background-color: #191919 !important;
  z-index: 99; }
  .bg-light .navbar-toggler {
    border-color: #506cea; }
    .bg-light .navbar-toggler .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(88, 130, 253, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .bg-light .navbar-brand {
    color: #191919;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 25px;
    font-weight: 700; }
    .bg-light .navbar-brand span {
      color: #506cea; }
  .bg-light .navbar-nav .nav-link {
    text-transform: uppercase;
    font-family: 'Conv_Campton-Light';
    color: #ffffff !important;
    font-size: 16px;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px; }
    .bg-light .navbar-nav .nav-link:focus, .bg-light .navbar-nav .nav-link:hover {
      color: #ffffff; }
    .bg-light .navbar-nav .nav-link:last-child {
      padding-right: 0; }
    .bg-light .navbar-nav .nav-link.active, .bg-light .navbar-nav .nav-link.show {
      color: #ffffff;
      font-family: 'Conv_Campton-SemiBold'; }
    .bg-light .navbar-nav .nav-link.active .nav-link, .bg-light .navbar-nav .nav-link.show > .nav-link {
      color: #ffffff;
      font-family: 'Conv_Campton-SemiBold'; }
  .bg-light .navbar-nav .dropdown .dropdown-menu {
    min-width: 15rem;
    padding: 15px 15px;
    margin: 0;
    top: 115%;
    background-color: #ffffff;
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    border: none;
    border-radius: 10px;
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.4s; }
    .bg-light .navbar-nav .dropdown .dropdown-menu .dropdown-item {
      padding: 0;
      margin: 15px 0;
      background: transparent;
      color: #333333; }
      .bg-light .navbar-nav .dropdown .dropdown-menu .dropdown-item:hover, .bg-light .navbar-nav .dropdown .dropdown-menu .dropdown-item.active {
        color: #506cea; }
  .bg-light .navbar-nav .dropdown:hover .dropdown-menu {
    top: 100%;
    opacity: 1;
    visibility: visible; }

/* ==============
    End Navbar CSS
==================================== */
/* ==============
    Start Main Banner CSS
==================================== */
.main-banner {
  height: 680px;
  position: relative; }

.main-banner-content {
  text-align: left;
  margin-top: 50px;
  position: relative;
  z-index: 2; }
  .main-banner-content h1 {
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 20px; }
  .main-banner-content p {
    margin-bottom: 30px; }
  .main-banner-content .btn-primary {
    margin-right: 13px; }
  .main-banner-content .btn-secondary i {
    margin-right: 4px; }

.banner-img {
  text-align: center; }

.pattern {
  background: url(../../assets/img/pattern.png);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 210px;
  z-index: 1; }

.pattern-2 {
  background: url(../../assets/img/pattern-2.png);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px; }

.creative-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  background: #506cea;
  height: 100%;
  border-radius: 100% 0 0 0; }

.creative-bg-two {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  background: #506cea;
  height: 100%;
  border-radius: 0 0 0 100%; }

/* ==============
    End Main Banner CSS
==================================== */
/* ==============
    Start Features CSS
==================================== */
.features-area {
  padding-bottom: 70px; }

.single-features {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff;
  padding: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .single-features i {
    font-size: 40px;
    display: inline-block;
    -moz-transition: 0.4s;
    color: #506cea;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .single-features h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .single-features p {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .single-features:hover {
    background: #506cea; }
    .single-features:hover h3, .single-features:hover p, .single-features:hover i {
      color: #ffffff; }

/* ==============
    End Features CSS
==================================== */
/* ==============
    Start About CSS
==================================== */
.about-area {
  position: relative;
  background-image: url(../../assets/img/pattern-4.png);
  background-attachment: fixed; }

.about-content {
  margin-top: 20px; }
  .about-content h3 {
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 15px; }
  .about-content p {
    margin-bottom: 25px; }
  .about-content .pull-left {
    float: left;
    margin-right: 45px !important; }
  .about-content ul {
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .about-content ul li {
      color: #6F8BA4;
      margin-top: 10px;
      font-weight: 400;
      text-align: left; }
      .about-content ul li:first-child {
        margin-top: 0; }
      .about-content ul li i {
        margin-right: 4px;
        color: #506cea; }
  .about-content .btn {
    margin-top: 25px; }

.about-text span {
  display: inline-block;
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
  width: 50px;
  height: 50px;
  background: #506cea;
  text-align: center;
  line-height: 50px;
  border-radius: 50%; }

.about-text h3 {
  font-size: 26px;
  margin-bottom: 15px;
  margin-top: 25px; }

.about-text ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 15px; }
  .about-text ul li {
    color: #6F8BA4;
    margin-top: 10px;
    font-weight: 400; }
    .about-text ul li i {
      margin-right: 4px;
      color: #506cea; }
    .about-text ul li:first-child {
      margin-top: 0; }

.about-text .blockquote {
  margin-bottom: 0;
  background: #506cea;
  padding: 15px;
  margin-top: 15px;
  border-radius: 5px; }
  .about-text .blockquote p {
    color: #ffffff; }

/* ==============
    End About CSS
==================================== */
/* ==============
    Start Services CSS
==================================== */
.services-area {
  padding-bottom: 70px; }

.single-services {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff;
  border-radius: 5px;
  padding: 30px;
  cursor: pointer;
  margin-bottom: 30px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .single-services i {
    font-size: 30px;
    color: #ffffff;
    width: 65px;
    height: 65px;
    text-align: center;
    background: #506cea;
    line-height: 65px;
    border-radius: 50%;
    display: inline-block;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .single-services h3 {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 20px;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .single-services p {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .single-services:hover {
    background: #506cea; }
    .single-services:hover i {
      background: #ffffff;
      color: #506cea; }
    .single-services:hover h3, .single-services:hover p {
      color: #ffffff; }

/* ==============
    End Services CSS
==================================== */
/* ==============
    Start Vision CSS
==================================== */
.vision-area {
  background-image: url(../../assets/img/pattern-4.png);
  background-attachment: fixed; }

.tab {
  overflow: hidden; }
  .tab .tab_content .react-tabs .react-tabs__tab-list {
    list-style-type: none;
    margin-bottom: 40px !important;
    padding-left: 0 !important;
    border-bottom: 2px solid #edf5ff !important;
    padding-bottom: 35px !important;
    display: -ms-flexbox !important;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .tab .tab_content .react-tabs .react-tabs__tab-list li {
      -ms-flex: 0 0 16.6666666667%;
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
      padding-right: 15px;
      padding-left: 15px;
      text-align: center;
      color: #191919;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      display: inline-block;
      border: none;
      bottom: 0;
      box-shadow: unset !important;
      outline: 0 !important;
      padding: 0;
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li:hover {
        color: #506cea; }
        .tab .tab_content .react-tabs .react-tabs__tab-list li:hover i {
          color: #ffffff;
          background: #506cea; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li:first-child {
        padding-left: 0; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li:last-child {
        padding-right: 0; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li i {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        background: #edf5ff;
        text-align: center;
        font-size: 30px;
        border-radius: 50%;
        margin-bottom: 15px;
        -moz-transition: 0.4s;
        -webkit-transition: 0.4s;
        transition: 0.4s; }
    .tab .tab_content .react-tabs .react-tabs__tab-list .react-tabs__tab--selected {
      color: #506cea; }
      .tab .tab_content .react-tabs .react-tabs__tab-list .react-tabs__tab--selected i {
        color: #ffffff;
        background: #506cea; }
  .tab .tab_content .react-tabs .tabs_item {
    display: none; }
    .tab .tab_content .react-tabs .tabs_item:first-child {
      display: block; }
    .tab .tab_content .react-tabs .tabs_item .tabs_item_content h3 {
      font-size: 22px;
      margin-bottom: 15px; }
    .tab .tab_content .react-tabs .tabs_item .tabs_item_content ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      margin-top: 15px; }
      .tab .tab_content .react-tabs .tabs_item .tabs_item_content ul li {
        color: #6f8ba4;
        margin-top: 10px;
        font-weight: 400; }
        .tab .tab_content .react-tabs .tabs_item .tabs_item_content ul li:first-child {
          margin-top: 0; }
        .tab .tab_content .react-tabs .tabs_item .tabs_item_content ul li i {
          margin-right: 4px;
          color: #506cea; }

/* ==============
    End Vision CSS
==================================== */
/* ==============
    Start Fun Facts CSS
==================================== */
.funfacts-area {
  background-image: url(../../assets/img/parallax.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  position: relative; }
  .funfacts-area::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #506cea;
    left: 0;
    opacity: 0.84; }

.funFact {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .funFact:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  .funFact::before, .funFact::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 75px;
    background: #ffffff;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .funFact::after {
    top: auto;
    bottom: 0; }
  .funFact i {
    font-size: 28px;
    background: #ffffff;
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 1px solid #ffffff;
    line-height: 61px;
    color: #506cea;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .funFact h3 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 10px; }
  .funFact p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 0; }
  .funFact:hover::before, .funFact:hover::after {
    height: 0; }
  .funFact:hover i {
    color: #ffffff;
    background: transparent; }

/* Fun Fact Two */
.fun-fact {
  position: relative;
  padding-left: 80px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .fun-fact i {
    font-size: 28px;
    background: #ffffff;
    display: inline-block;
    width: 60px;
    position: absolute;
    color: #506cea;
    left: 0;
    top: 0;
    height: 60px;
    border: 1px solid #ffffff;
    line-height: 61px;
    text-align: center;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .fun-fact h3 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 10px; }
  .fun-fact p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 0; }
  .fun-fact:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
    .fun-fact:hover i {
      color: #ffffff;
      background: transparent; }

/* ==============
    End Fun Facts CSS
==================================== */
/* ==============
    Start Join Now CSS
==================================== */
.join-content {
  margin-top: 45px; }
  .join-content h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 20px; }
  .join-content p {
    margin-bottom: 25px; }
  .join-content .btn-primary {
    margin-right: 6px; }

/* ==============
    End Join Now CSS
==================================== */
/* ==============
    Start How It Works CSS
==================================== */
.single-box {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
  position: relative; }
  .single-box.with-line::before {
    content: "";
    position: absolute;
    z-index: 1;
    background: url(../../assets/img/arrow-big.png) no-repeat center center;
    width: 217px;
    height: 210px;
    top: 10px;
    right: -85px; }
  .single-box span {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 84px;
    text-align: center;
    font-size: 30px;
    margin-bottom: 25px;
    background: #edf5ff;
    border-radius: 50%;
    font-weight: 700;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .single-box h3 {
    font-size: 24px;
    margin-bottom: 20px; }
  .single-box:hover span {
    color: #ffffff;
    background: #506cea; }

/* ==============
    End How It Works CSS
==================================== */
/* ==============
    Start Team CSS
==================================== */
.our-team {
  text-align: center;
  position: relative; }
  .our-team .pic {
    border-radius: 50%;
    overflow: hidden;
    position: relative; }
    .our-team .pic::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(53, 53, 53, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      transform: scale(1);
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
  .our-team img {
    border-radius: 50%;
    transform: scale(1);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .our-team .social {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 2;
    list-style-type: none;
    opacity: 0;
    transform: scale(1.2);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
    .our-team .social li {
      display: inline-block; }
      .our-team .social li a {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 50%;
        color: #506cea;
        margin: 0 3px;
        background: #ffffff;
        text-decoration: none; }
        .our-team .social li a:hover {
          color: #ffffff;
          background: #506cea; }
  .our-team .title {
    font-size: 22px;
    font-weight: 700;
    color: #191919;
    text-transform: capitalize;
    margin-top: 25px;
    margin-bottom: 10px; }
  .our-team .post {
    display: inline-block;
    color: #6F8BA4; }
  .our-team:hover .pic::before {
    opacity: 1;
    transform: scale(0.95);
    visibility: visible; }
  .our-team:hover img {
    transform: scale(1.3); }
  .our-team:hover .social {
    opacity: 1;
    transform: scale(1); }

/* Team Style Two */
.single-team {
  text-align: center; }
  .single-team .pic {
    position: relative; }
    .single-team .pic::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.55);
      opacity: 0;
      z-index: 1;
      border: 2px solid #506cea;
      visibility: hidden;
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .single-team .pic::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
      z-index: 2;
      visibility: hidden;
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
  .single-team .social-links {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 40px;
    left: 0;
    opacity: 0;
    z-index: 6;
    visibility: hidden;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
    .single-team .social-links li {
      display: inline-block; }
      .single-team .social-links li a {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 3px;
        line-height: 30px;
        border-radius: 50%;
        font-size: 15px;
        color: #ffffff;
        border: 1px solid #ffffff;
        text-decoration: none; }
        .single-team .social-links li a:hover {
          background: #506cea;
          border-color: #506cea; }
  .single-team .team-content {
    padding: 20px 0;
    background: #edf5ff;
    border-top: 2px solid #506cea; }
    .single-team .team-content .title {
      font-size: 22px;
      font-weight: 700;
      color: #191919;
      text-transform: capitalize;
      margin-bottom: 10px;
      position: relative; }
    .single-team .team-content .post {
      display: inline-block;
      color: #6F8BA4;
      position: relative; }
  .single-team:hover .pic::before, .single-team:hover .pic::after {
    opacity: 1;
    visibility: visible; }
  .single-team:hover .pic::before {
    -webkit-transform: scale(1);
    transform: scale(1); }
  .single-team:hover .pic::after {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  .single-team:hover .social-links {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1); }

/* ==============
    End Team CSS
==================================== */
/* ==============
    Start Testimonials CSS
==================================== */
.testimonials-area {
  padding-bottom: 70px;
  position: relative; }

.testimonials-item {
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: 30px;
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .testimonials-item .client-info {
    position: relative;
    padding-left: 110px;
    margin-bottom: 40px; }
    .testimonials-item .client-info .img {
      position: absolute;
      left: 0;
      top: 0; }
      .testimonials-item .client-info .img img {
        border-radius: 50%; }
    .testimonials-item .client-info .client-title {
      display: inline-block;
      margin-top: 14px; }
      .testimonials-item .client-info .client-title h4 {
        font-size: 20px;
        margin-bottom: 8px;
        text-transform: uppercase; }
      .testimonials-item .client-info .client-title span {
        display: inline-block;
        color: #506cea; }
      .testimonials-item .client-info .client-title span, .testimonials-item .client-info .client-title h4 {
        position: relative;
        z-index: 1;
        -moz-transition: 0.4s;
        -webkit-transition: 0.4s;
        transition: 0.4s; }
  .testimonials-item p {
    position: relative;
    z-index: 1;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .testimonials-item i {
    position: absolute;
    right: 28px;
    bottom: 20px;
    font-size: 98px;
    color: #edf5ff;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .testimonials-item:hover {
    background: #506cea; }
    .testimonials-item:hover .client-info .client-title span, .testimonials-item:hover .client-info .client-title h4 {
      color: #ffffff; }
    .testimonials-item:hover p {
      color: #ffffff; }
    .testimonials-item:hover i {
      color: #636363; }

/* ==============
    End Testimonials CSS
==================================== */
/* ==============
    Start FAQ CSS
==================================== */
.accordion {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .accordion .accordion-item {
    display: block;
    margin-bottom: 15px; }
    .accordion .accordion-item:last-child {
      margin-bottom: 0; }
  .accordion .accordion-title {
    display: block;
    color: #ffffff !important;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    background: #506cea; }
    .accordion .accordion-title:hover {
      background-color: #e54545; }
  .accordion .active .accordion-title {
    background: #e54545;
    border-radius: 0; }
  .accordion .accordion-content {
    display: none;
    position: relative;
    overflow: hidden;
    padding: 15px 15px 0; }
  .accordion .accordion-content.show {
    display: block; }

/* ==============
    End FAQ CSS
==================================== */
/* ==============
    Start Pricing CSS
==================================== */
.pricingTable {
  padding-bottom: 30px;
  background: #ffffff;
  border-radius: 5px;
  text-align: center;
  position: relative;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .pricingTable .title {
    padding: 30px 20px 192px;
    margin: 0 0 30px 0;
    font-size: 25px;
    color: #ffffff;
    text-transform: uppercase;
    overflow: hidden;
    position: relative;
    background: #506cea; }
    .pricingTable .title::before, .pricingTable .title::after {
      content: "";
      width: 280px;
      height: 200px;
      border-radius: 80px;
      background: #ffffff;
      position: absolute;
      bottom: -175px;
      left: -46px;
      transform: rotate(-85deg); }
    .pricingTable .title::after {
      border-radius: 100px;
      bottom: auto;
      top: 150px;
      left: auto;
      right: -70px;
      transform: rotate(-40deg); }
  .pricingTable .price-value {
    display: inline-block;
    width: 140px;
    height: 140px;
    line-height: 65px;
    border-radius: 50%;
    background: #ffffff;
    -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.3);
    padding: 30px 0;
    font-size: 30px;
    font-weight: 600;
    position: absolute;
    top: 100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .pricingTable .month {
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 0; }
  .pricingTable .pricing-content {
    list-style: none;
    padding: 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 25px;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
    .pricingTable .pricing-content li {
      margin-bottom: 12px;
      color: #737373; }
      .pricingTable .pricing-content li:last-child {
        margin-bottom: 0; }
  .pricingTable:hover .price-value {
    color: #ffffff;
    background: #506cea; }

/* Pricing Style Two */
.pricing-table {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  padding: 50px 0 30px;
  text-align: center;
  margin-top: 33px;
  border-radius: 5px;
  position: relative;
  background: #ffffff;
  cursor: pointer; }
  .pricing-table .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    background: #ffffff;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    margin: 0 auto;
    font-size: 25px;
    position: absolute;
    color: #506cea;
    top: -34px;
    left: 0;
    right: 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .pricing-table .pricing-table-header {
    margin-bottom: 25px;
    margin-top: 20px;
    padding: 20px 0;
    background: #506cea; }
  .pricing-table .title {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    color: #ffffff; }
  .pricing-table .price-value {
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    border-bottom: 3px solid #ffffff; }
    .pricing-table .price-value b {
      font-weight: 500;
      font-size: 15px; }
  .pricing-table .pricing-content {
    list-style: none;
    padding: 0;
    margin: 0 0 25px 0; }
    .pricing-table .pricing-content li {
      margin-bottom: 12px;
      color: #737373; }
      .pricing-table .pricing-content li:last-child {
        margin-bottom: 0; }
  .pricing-table:hover .icon {
    color: #ffffff;
    background: #506cea; }

/* ==============
    End Pricing CSS
==================================== */
/* ==============
    Start Partner CSS
==================================== */
.partner-area .item {
  text-align: center; }

.partner-area .owl-item img {
  border: 1px solid #506cea;
  display: inline-block; }

/* ==============
    End Partner CSS
==================================== */
/* ==============
    End Subscribe CSS
==================================== */
.subscribe-area {
  text-align: center;
  position: relative;
  background: #506cea; }

.newsletter {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background: #ffffff;
  padding: 35px;
  border-radius: 5px; }
  .newsletter h4 {
    text-transform: capitalize;
    margin-bottom: 30px;
    font-size: 30px; }
    .newsletter h4 span {
      text-transform: uppercase;
      font-weight: 600;
      color: #506cea; }
  .newsletter .subscribe-form {
    position: relative; }
    .newsletter .subscribe-form .form-control {
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
      border: none;
      height: 75px;
      border-radius: 50px;
      padding-left: 20px;
      font-size: 17px; }
    .newsletter .subscribe-form .btn {
      position: absolute;
      right: 7px;
      border-radius: 30px;
      top: 8px;
      height: 60px;
      text-transform: uppercase;
      width: 175px; }
    .newsletter .subscribe-form .validation-danger {
      margin-top: 15px;
      color: red; }
    .newsletter .subscribe-form .validation-success {
      margin-top: 15px; }

.subs-sending-msg {
  color: black; }

.subs-sending-msg {
  color: #6993ff; }

.subs-error-msg {
  color: #ff7344; }

.bg-top {
  position: absolute;
  width: 290px;
  height: 393px;
  top: 0px;
  left: 0px;
  background: url(../../assets/img/bg-top.png); }

.bg-bottom {
  position: absolute;
  width: 350px;
  height: 393px;
  bottom: 0px;
  right: 0px;
  background: url(../../assets/img/bg-bottom.png); }

.subscribe-area .pattern-2 {
  height: 100px; }

.subscribe-area .pattern {
  height: 135px; }

/* ==============
    End Subscribe CSS
==================================== */
/* ==============
    Start Page Title CSS
==================================== */
.page-title {
  position: relative;
  height: 350px;
  background: #506cea; }
  .page-title .page-title-content {
    text-align: center;
    position: relative;
    margin-top: -50px; }
    .page-title .page-title-content h3 {
      font-size: 25px;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: #ffffff; }
    .page-title .page-title-content ul {
      padding: 0;
      margin: 0;
      list-style-type: none; }
      .page-title .page-title-content ul li {
        display: inline-block;
        color: #eeeeee;
        font-size: 16px; }
        .page-title .page-title-content ul li a {
          display: inline-block;
          color: #ffffff;
          text-decoration: none; }

/* ==============
    End Page Title CSS
==================================== */
/* ==============
    Start Contact CSS
==================================== */
.contact-box {
  background: #ffffff;
  padding: 20px 20px 20px 90px;
  border-radius: 5px;
  position: relative;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .contact-box .icon {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 47px;
    border: 1px dashed #506cea;
    color: #506cea;
    border-radius: 50%;
    font-size: 22px;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .contact-box .content h4 {
    font-size: 20px;
    margin-bottom: 12px; }
  .contact-box .content p a {
    color: #777777;
    text-decoration: none; }
    .contact-box .content p a:hover {
      color: #506cea; }
  .contact-box:hover {
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
    .contact-box:hover .icon {
      color: #ffffff;
      background: #506cea; }

#map {
  width: 100%;
  height: 400px;
  margin-top: 40px;
  margin-bottom: 40px;
  position: inherit; }

.googleMap {
  margin: 40px 0; }

.contact-text h3 {
  font-size: 22px;
  margin-bottom: 0; }

.contact-text p {
  margin-top: 20px; }

.form-control {
  height: 45px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-weight: 300;
  font-size: 16px;
  box-shadow: unset !important;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .form-control:focus {
    box-shadow: unset;
    padding-left: 20px; }

.form-trial {
  height: 45px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-weight: 300;
  font-size: 16px;
  box-shadow: unset !important;
  transition: 0.4s; }

.form-trial {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border-bottom: 1px solid #ced4da; }

.textcomment {
  height: 100px; }

.form-group label {
  color: #fff;
  font-family: 'Conv_Campton-SemiBold';
  font-size: 12px;
  letter-spacing: 5px; }

.form-trial:focus {
  color: #495057;
  background-color: transparent;
  border-color: none;
  outline: 0; }

#contactForm .list-unstyled li {
  color: red; }

#contactForm .btn {
  text-transform: uppercase;
  padding: 10px 35px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px; }

#contactForm .contactMsg {
  color: #506cea; }

/* ==============
    End Contact CSS
==================================== */
/* ==============
    Start Footer CSS
==================================== */
.footer-area {
  padding-top: 50px;
  padding-bottom: 0;
  background: #191919; }

.single-footer {
  text-align: left; }
  .single-footer .logo {
    margin-top: -6px;
    margin-bottom: 15px; }
    .single-footer .logo a {
      color: #191919;
      text-transform: uppercase;
      font-size: 25px;
      font-weight: 700;
      text-decoration: none; }
      .single-footer .logo a span {
        color: #506cea; }
  .single-footer .btn {
    margin-top: 15px; }
  .single-footer h3 {
    font-size: 24px;
    margin-top: -5px;
    margin-bottom: 25px; }
  .single-footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .single-footer ul li a {
      color: #6f8ba4;
      margin-top: 8px;
      display: block;
      text-decoration: none;
      font-size: 17px; }
      .single-footer ul li a i {
        margin-right: 4px; }
      .single-footer ul li a:hover {
        padding-left: 7px;
        color: #506cea; }
    .single-footer ul li:first-child a {
      margin-top: 0; }
  .single-footer .contact-info {
    margin-top: 15px; }
    .single-footer .contact-info li {
      margin-bottom: 10px;
      color: #737373;
      position: relative;
      padding-left: 25px; }
      .single-footer .contact-info li:last-child {
        margin-bottom: 0; }
      .single-footer .contact-info li i {
        position: absolute;
        left: 0;
        top: 3px;
        color: #506cea;
        font-size: 20px; }

.copyright-area {
  margin-top: 0px;
  padding: 30px 0; }
  .copyright-area p {
    color: #ffffff;
    margin-top: 6px; }
  .copyright-area ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .copyright-area ul li {
      display: inline-block; }
      .copyright-area ul li a {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 33px;
        text-align: center;
        margin-left: 4px;
        border-radius: 50%;
        text-decoration: none; }

/* ==============
    End Footer CSS
==================================== */
/* ==============
    Scroll Top
==================================== */
aside.ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
  background-color: #506cea !important;
  transition: 1s !important;
  outline: 0 !important; }

/* ==============
    Router Transitions
==================================== */
.fade-enter {
  opacity: 0.01; }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in; }

.fade-exit {
  opacity: 1; }
  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in; }

div.transition-group {
  position: relative; }

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }

/*********************************************************** MY CSS *************************************************************/
.row {
  padding-bottom: 20px; }

.header_row {
  width: 100%;
  padding-bottom: 0px; }

.ml-auto.navbar-nav {
  padding-top: 0px; }

.logo {
  padding-top: 20px; }

.heaader_cart {
  text-align: left;
  position: absolute;
  color: white;
  font-size: 18px;
  z-index: 0; }
  .heaader_cart .cart_count {
    position: absolute;
    font-size: 12px;
    z-index: 9999;
    background-color: #e54545;
    padding: 2px 5px;
    border-radius: 50%;
    float: right;
    margin-left: 15px;
    margin-top: -2px;
    text-align: center;
    font-family: 'Conv_Campton-Bold'; }

.heaader_cart a {
  color: white !important;
  font-size: 18px; }

.header_right_side {
  position: relative;
  padding-top: 20px;
  text-align: right; }

.header_right_side span.search_icon {
  padding-right: 20px; }

.header_right_side span.bell_icon {
  position: relative;
  padding-right: 20px; }

.bell_icon span.notif_count {
  position: absolute;
  background: #e54545;
  padding: 1px 7px;
  border-radius: 50%;
  left: 8px;
  top: -13px;
  font-family: 'Conv_Campton-Light';
  font-size: 16px;
  color: #ffffff; }

.top_footer {
  padding-top: 30px; }

span.vertical_line {
  padding-left: 10px; }

hr {
  max-width: 95%;
  background: #353535;
  margin-bottom: 0px; }

.why_scout_slider {
  position: relative;
  width: 100%; }

.why_scout_slider .carousel .thumbs-wrapper {
  display: none; }

.carousel .slider-wrapper.axis-horizontal .slider {
  position: relative; }

.slider_text_btns {
  position: absolute;
  bottom: 10%;
  left: 10%;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px; }

.slider_text_btns h3 {
  font-family: 'Conv_Campton-Light';
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 30px; }

.slider_text_btns h3 span {
  font-family: 'Conv_Campton-Black'; }

.slider_text_btns h1 {
  font-family: 'Conv_Campton-Light';
  font-size: 48px;
  color: #ffffff; }

.slider_text_btns .video_btn {
  margin-top: 50px; }

.why_scout_slider .carousel.carousel-slider .control-arrow {
  top: 50%;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  background: #e54545;
  opacity: 1;
  width: 100px;
  height: 50px; }

.why_scout_slider .carousel.carousel-slider .control-arrow:hover {
  background: #e54545; }

.book_banner {
  margin: 0 auto;
  padding-top: 40px;
  text-align: center; }

.book_banner img {
  border: 10px solid #ccc; }

.prelogin_taste {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 5px;
  font-family: 'Conv_Campton-SemiBold';
  padding-top: 20px;
  padding-bottom: 0px;
  text-align: center; }

.section_title_prelogin {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Conv_Campton-Light';
  padding-top: 10px;
  padding-bottom: 50px; }

.section_title_prelogin span {
  font-family: 'Conv_Campton-SemiBold'; }

.section_title {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Conv_Campton-Light';
  padding-top: 50px;
  padding-bottom: 50px; }

.section_title span {
  font-family: 'Conv_Campton-SemiBold'; }

.popular_slick_slider {
  position: relative; }

.popular_slick_slider .popular_slider_btn {
  left: 0;
  bottom: 60px; }

.popular_slider_btn span {
  padding-right: 10px;
  padding-left: 0;
  color: #ffffff; }

.popular_slick_slider .slick-initialized .slick-slide {
  position: relative; }

.data_on_hover {
  display: none; }

.popular_slick_slider .slick-initialized .slick-slide:hover {
  transform: scale(1.1);
  transition-duration: 500ms;
  transition-delay: 0ms;
  z-index: 9999; }
  .popular_slick_slider .slick-initialized .slick-slide:hover .data_on_hover {
    display: block;
    background: #e54545;
    padding: 5px;
    position: absolute;
    top: 60px;
    left: 10%;
    color: #ffffff;
    font-size: 13px;
    font-family: 'Conv_Campton-Light'; }

.popular_slick_slider .slick-slide img {
  width: 100%;
  height: 200px;
  padding-right: 10px; }

.slick-prev {
  left: -40px !important;
  z-index: 9999; }

.slick-next {
  right: -33px !important;
  z-index: 9999; }

.slick-prev, .slick-next {
  width: 40px !important;
  height: 70px !important;
  background: #e54545 !important;
  top: 45% !important;
  z-index: 0; }

.crushed {
  background: #191919;
  padding: 50px; }

.it_crushed_me {
  padding: 40px; }
  .it_crushed_me h1 {
    color: #ffffff;
    font-size: 48px;
    font-family: 'Conv_Campton-SemiBold';
    letter-spacing: 5px;
    padding-bottom: 20px; }
  .it_crushed_me h2 {
    color: #ffffff;
    font-size: 24px;
    font-family: 'Conv_Campton-SemiBold';
    letter-spacing: 1px; }
  .it_crushed_me h3 {
    color: #ffffff;
    font-size: 22px;
    font-family: 'Conv_Campton-Light';
    padding-bottom: 20px;
    padding-top: 30px; }
  .it_crushed_me span {
    font-family: 'Conv_Campton-SemiBold'; }
  .it_crushed_me .it_crushed_btns {
    margin-top: 50px; }
    .it_crushed_me .it_crushed_btns button {
      margin-right: 50px; }

.latest_events {
  position: relative;
  padding-right: 10px; }
  .latest_events .event_header {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .latest_events .event_header .event_red_side {
      width: 50%;
      background: #e54545;
      padding: 5px;
      text-align: center;
      font-family: 'Conv_Campton-SemiBold';
      font-size: 40px;
      color: #ffffff; }
    .latest_events .event_header .event_black_side {
      width: 50%;
      background: #2a2a2a;
      padding: 5px;
      text-align: center;
      font-family: 'Conv_Campton-SemiBold';
      font-size: 40px;
      color: #ffffff; }
  .latest_events .event_content {
    padding: 30px;
    background: #ffffff;
    height: 300px !important; }
    .latest_events .event_content h3 {
      color: #323232;
      font-family: 'Conv_Campton-SemiBold';
      font-size: 24px;
      padding-bottom: 15px; }
    .latest_events .event_content p {
      color: #323232;
      line-height: 18px;
      font-size: 16px;
      font-family: 'Conv_Campton-Medium';
      margin-bottom: 20px; }
    .latest_events .event_content .arrow_view_more {
      position: absolute;
      bottom: 20px; }
      .latest_events .event_content .arrow_view_more img {
        display: inline-block;
        width: 30px;
        height: 8px; }
      .latest_events .event_content .arrow_view_more span {
        color: #e54545;
        font-size: 13px;
        font-family: 'Conv_Campton-SemiBold';
        padding-left: 5px;
        letter-spacing: 1px; }

.subscribe_prime {
  position: relative;
  background: #e54545;
  padding: 30px;
  cursor: pointer; }
  .subscribe_prime .subscribe_prime_text {
    color: #ffffff;
    font-size: 36px;
    font-family: 'Conv_Campton-Light'; }

.sub_mail {
  position: absolute;
  right: 0;
  top: 0;
  background: #2a2a2a;
  width: 250px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex; }

.dropbtn {
  background-color: transparent;
  color: white;
  padding: 5px;
  font-size: 16px;
  border: none; }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #2a2a2a;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left; }

.dropdown-content a {
  color: #ffffff !important;
  font-family: 'Conv_Campton-Light';
  font-size: 16px;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.dropdown-content a:hover {
  background-color: transparent; }

.dropdown:hover .dropdown-content {
  display: block;
  margin-top: -3px; }

.dropdown:hover .dropbtn {
  background-color: transparent; }

.theshift_header {
  background: url(../images/theshift-bg.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 30px; }

.webinar_header {
  background: url(../images/webinar-bg.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 30px; }

.theshift_subtitle {
  color: #ffffff;
  font-size: 18px;
  font-family: 'Conv_Campton-Light'; }

.theshift_subtitle span {
  font-family: 'Conv_Campton-SemiBold'; }

.theshift_title {
  color: #ffffff;
  font-size: 48px;
  letter-spacing: 5px;
  padding: 5px 0px;
  font-family: 'Conv_Campton-Bold'; }

.theshift_section {
  padding: 50px 0px; }

.bg_gray {
  background-color: #1e1e1e; }

.bg_red {
  background-color: #e54545; }

.txt_red {
  color: #e54545; }

.marginBottom0 {
  margin-bottom: 0px; }

.videoFrame {
  margin-top: 30px; }

.theshift_cover {
  text-align: center; }

.theshift_about {
  margin-top: 50px; }

.about_redtitle {
  font-family: 'Conv_Campton-Light';
  color: #e54545;
  font-size: 32px;
  letter-spacing: 5px;
  padding: 10px 0px; }

.theshift_section p {
  color: #ffffff;
  font-family: 'Conv_Campton-Light';
  margin-bottom: 30px; }

.purchase_btn {
  padding: 9px 10px !important;
  font-size: 14px;
  letter-spacing: 4.5px;
  font-family: 'Conv_Campton-SemiBold';
  border: 1px solid #e54545;
  margin: 20px 0; }

.order_btn {
  padding: 9px 60px !important;
  font-size: 14px;
  letter-spacing: 4.5px;
  font-family: 'Conv_Campton-SemiBold';
  margin: 20px 0; }

.btn_note {
  color: #cccccc;
  margin-top: 20px;
  font-family: 'Conv_Campton-Light';
  font-size: 12px; }

.chapter_section {
  background-color: #1e1e1e;
  padding: 50px 80px;
  margin-top: 30px;
  border-left: 5px solid #e54545; }

.prelogin_bg {
  background: url(../images/prelogin_bg.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center; }
  .prelogin_bg .title_one {
    font-family: 'Conv_Campton-SemiBold';
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 50px; }
  .prelogin_bg .title_two {
    font-family: 'Conv_Campton-Light';
    color: #ffffff;
    font-size: 28px; }
    .prelogin_bg .title_two span {
      font-family: 'Conv_Campton-Black'; }

.header_btn {
  letter-spacing: 5px !important;
  margin: 0 auto;
  text-align: center;
  margin: 50px 20px; }
  .header_btn .icon {
    margin-right: 20px; }

.prelogin_iconbox {
  text-align: center;
  background: #e54545;
  padding-top: 60px;
  padding-bottom: 60px; }
  .prelogin_iconbox img {
    margin-bottom: 20px; }
  .prelogin_iconbox h3 {
    font-family: 'Conv_Campton-Light';
    color: #ffffff;
    font-size: 22px; }

.trial_formwrapper {
  width: 50%;
  margin: 0 auto; }

.page_bg {
  background: url(../images/page-bg.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px; }
  .page_bg .signup_form {
    width: 620px;
    max-width: 95%;
    margin: 0 auto;
    background: #000000;
    opacity: 0.7;
    padding: 50px;
    text-align: center; }
    .page_bg .signup_form h1 {
      color: #eaeaea;
      font-family: 'Conv_Campton-Light';
      font-size: 48px;
      padding-bottom: 50px; }
    .page_bg .signup_form .input_field {
      border-radius: 0px;
      background: #3d393e;
      opacity: 1;
      height: 60px;
      color: #eaeaea;
      font-family: 'Conv_Campton-MediumItalic';
      /* margin-bottom: 40px; */
      text-align: left;
      text-indent: 20px; }
    .page_bg .signup_form .already_signup {
      color: #eaeaea;
      font-family: 'Conv_Campton-Light';
      font-size: 16px;
      padding-top: 40px; }
      .page_bg .signup_form .already_signup span {
        color: #e54545; }

.invalid-feedback {
  /* margin-top: -25px !important; */ }

div#basic-navbar-nav {
  padding-top: 15px; }

.bg-light .navbar-nav .nav-link:last-child {
  padding-right: 10px; }

.navbar-expand-lg .navbar-collapse {
  align-items: center;
  justify-content: center; }

.music_back {
  background: #e54545;
  padding: 20px;
  margin-bottom: 50px; }
  .music_back .music_title {
    position: absolute;
    background: #952f2f;
    top: 0;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .music_back .music_title span {
      font-size: 64px;
      font-family: 'Conv_Campton-Light';
      font-weight: lighter;
      color: #ffffff;
      padding-right: 50px; }
  .music_back button {
    background: #952f2f;
    float: right;
    margin-right: 80px; }

.events_banner {
  margin: 0 auto;
  position: relative;
  display: block;
  padding-top: 70px;
  width: 100%; }

.main_page_title {
  position: absolute;
  background: #952f2f;
  top: -20px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .main_page_title span {
    font-size: 64px;
    font-family: 'Conv_Campton-Light';
    font-weight: lighter;
    color: #ffffff;
    padding-right: 50px; }

.album_image {
  padding-top: 70px; }

.album_details {
  padding-top: 80px;
  color: #ffffff;
  letter-spacing: 3px; }
  .album_details h1 {
    color: #ffffff;
    font-size: 48px;
    font-family: 'Conv_Campton-Light';
    padding-bottom: 30px; }
  .album_details h5 {
    color: #ffffff;
    font-size: 13px;
    font-family: 'Conv_Campton-SemiBold';
    padding-bottom: 10px; }
    .album_details h5 span {
      font-family: 'Conv_Campton-Light'; }
  .album_details h6 {
    color: #ffffff;
    font-size: 13px;
    font-family: 'Conv_Campton-SemiBold'; }
  .album_details button {
    position: absolute;
    bottom: 0; }

table.table.table-dark.custom_table {
  background-color: transparent; }

.table-dark th, .table-dark td, .table-dark thead th {
  padding: 15px; }

.ui.form .field {
  clear: none !important;
  position: relative; }

.ui.form .fields {
  position: relative; }

.ui.selection.dropdown {
  position: relative;
  color: #b3b3b3 !important;
  font-size: 19px !important;
  font-family: 'Conv_Campton-MediumItalic' !important;
  height: 60px !important;
  border-radius: 0px !important;
  border: none !important;
  line-height: 27px !important; }

.ui.dropdown:not(.button) > .default.text {
  color: #b3b3b3 !important; }

.ui.selection.dropdown > .dropdown.icon {
  line-height: 30px !important; }

.ui.form input[type="text"] {
  position: relative;
  color: #b3b3b3 !important;
  font-size: 19px !important;
  font-family: 'Conv_Campton-MediumItalic' !important;
  height: 60px !important;
  border-radius: 0px !important;
  border: none !important;
  background: #3d393e !important; }

.ui.form input[type="number"] {
  position: relative;
  color: #b3b3b3 !important;
  font-size: 19px !important;
  font-family: 'Conv_Campton-MediumItalic' !important;
  height: 60px !important;
  border-radius: 0px !important;
  border: none !important;
  background: #3d393e !important; }

.ui.form input[type="email"] {
  position: relative;
  color: #b3b3b3 !important;
  font-size: 19px !important;
  font-family: 'Conv_Campton-MediumItalic' !important;
  height: 60px !important;
  border-radius: 0px !important;
  border: none !important;
  background: #3d393e !important; }

.ui.form input[type="password"] {
  position: relative;
  color: #b3b3b3 !important;
  font-size: 19px !important;
  font-family: 'Conv_Campton-MediumItalic' !important;
  height: 60px !important;
  border-radius: 0px !important;
  border: none !important;
  background: #3d393e !important; }

.ui.form select {
  position: relative;
  color: #b3b3b3 !important;
  font-size: 19px !important;
  font-family: 'Conv_Campton-MediumItalic' !important;
  height: 60px !important;
  border-radius: 0px !important;
  border: none !important;
  background: #3d393e !important;
  margin-right: 10px; }

.ui.form textarea {
  background: #3d393e !important; }

.ui.button {
  background: #e54545 !important;
  border: none !important;
  color: #ffffff !important;
  font-family: 'Conv_Campton-SemiBold' !important;
  font-size: 13px !important;
  letter-spacing: 3px !important;
  border-radius: 20px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  position: relative;
  padding: 15px 30px !important; }

::-webkit-input-placeholder {
  /* Chrome */
  color: #b3b3b3 !important;
  transition: opacity 250ms ease-in-out; }

:focus::-webkit-input-placeholder {
  opacity: 0.5; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #b3b3b3 !important;
  transition: opacity 250ms ease-in-out; }

:focus:-ms-input-placeholder {
  opacity: 0.5; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #b3b3b3 !important;
  opacity: 1;
  transition: opacity 250ms ease-in-out; }

:focus::-moz-placeholder {
  opacity: 0.5; }

:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #b3b3b3 !important;
  opacity: 1;
  transition: opacity 250ms ease-in-out; }

:focus:-moz-placeholder {
  opacity: 0.5; }

.upload_profile_pic {
  background: #ffffff;
  text-align: center;
  padding: 40px;
  margin: 0 auto; }

.upload_profile_pic h5 {
  color: #b7b7b7;
  font-size: 16px;
  font-family: 'Conv_Arvo-Bold';
  letter-spacing: 5px;
  margin-bottom: 50px;
  padding-top: 20px; }

.upload_profile_pic h6 {
  color: #b7b7b7;
  font-size: 16px;
  font-family: 'Conv_Arvo-Italic';
  letter-spacing: 1px;
  padding-top: 50px;
  margin: 0; }

.upload_profile_pic .ui.multiple.dropdown > .label {
  margin: 0.7rem .28571429rem .14285714rem 0 !important;
  float: left; }

.upload_profile_pic .ui.label {
  background-color: #d8ab4c !important;
  font-family: 'Conv_Arvo-Bold';
  font-size: 13px;
  color: #fff !important;
  border-radius: 50px !important;
  border: none !important; }

.upload_profile_pic .ui.label > .delete.icon {
  color: #fff !important;
  opacity: 1; }

.inlineDim {
  display: inline-block;
  background: #ffffff;
  padding: 50px; }

.react-media-vis-body {
  height: auto !important; }

.react-media-vis-body + div {
  position: fixed !important;
  z-index: 999;
  left: 0;
  width: 100%; }

.react-media-vis-body + div .fa-list-ol {
  display: none; }

.react-media-vis-body + div .fa-react {
  display: none; }

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  color: #ffffff !important;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  border-radius: 0px;
  background: #3d393e;
  opacity: 1;
  color: #eaeaea;
  font-family: 'Conv_Campton-MediumItalic';
  margin-bottom: 40px;
  text-align: left;
  text-indent: 20px; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.user_profile_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: -5px; }

.inlineDim {
  position: fixed;
  display: inline-block;
  padding: 50px;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  right: 0;
  background: rgba(255, 255, 255, 0.8); }

.inlineDim .content {
  background: url("../images/loading.gif") 100%;
  width: 292px;
  height: 250px;
  margin: 20% auto; }

.inlineDim .content .loader {
  display: none; }

.favorites_item {
  position: relative;
  margin-bottom: 20px;
  cursor: pointer; }
  .favorites_item button {
    position: absolute;
    left: 0;
    bottom: 30px; }
  .favorites_item span {
    padding-right: 10px;
    padding-left: 0;
    color: #ffffff; }
  .favorites_item img {
    width: 100%;
    height: 204px; }
  .favorites_item .delete_icon i {
    position: absolute;
    right: 30px;
    top: 20px;
    background: #e54545;
    color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1; }
  .favorites_item .day_and_month {
    position: absolute;
    right: 15px;
    top: 0px;
    background: #e54545;
    color: #fff;
    padding: 10px;
    display: inline-block;
    text-align: center; }
    .favorites_item .day_and_month h3 {
      color: #ffffff;
      font-family: 'Conv_Campton-Black';
      font-size: 1.5rem; }
    .favorites_item .day_and_month h1 {
      color: #ffffff;
      font-family: 'Conv_Campton-Light';
      font-size: 2rem; }

.favorites_item:hover {
  transform: scale(1.1);
  transition-duration: 500ms;
  transition-delay: 0ms; }

.heart_icon i {
  position: absolute;
  right: 30px;
  top: 40px;
  background: #e54545;
  color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  z-index: 1; }

.heart_icon_fav i {
  position: absolute;
  right: 30px;
  top: 30px;
  background: #e54545;
  color: #952f2f;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center; }

.media_heart_icon i {
  color: #fff; }

.media_heart_icon_fav i {
  color: #952f2f; }

.react-confirm-alert-overlay {
  background: rgba(255, 255, 255, 0.4) !important; }

.delete_popup {
  font-family: 'Conv_Arvo-Bold';
  width: 400px;
  padding: 30px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666; }
  .delete_popup h3 {
    font-size: 22px;
    padding-bottom: 20px; }

.delete_popup button {
  background: #e54545;
  border: none;
  color: #ffffff !important;
  font-family: 'Conv_Campton-SemiBold';
  font-size: 16px;
  letter-spacing: 5px;
  border-radius: 0px;
  padding: 7px 20px;
  margin-right: 20px;
  cursor: pointer; }

.pagination > li > a {
  border-radius: 50% !important; }

.pagination > .disabled > a {
  color: #777;
  cursor: not-allowed;
  background-color: #e54545;
  border-color: #e54545;
  border-radius: 50%; }

.pagination > li > a {
  color: #ffffff !important;
  text-decoration: none;
  background-color: #e54545;
  border: 1px solid #e54545 !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  padding: 6px 10px !important; }

.pagination > li > a:hover {
  color: #fff !important;
  background-color: #e54545 !important; }

.pagination > .active > a {
  z-index: 3;
  color: #fff !important;
  cursor: default;
  background-color: #e54545 !important;
  border-color: #e54545 !important;
  border-radius: 50%; }

.music_title {
  position: absolute;
  background: #952f2f;
  top: 0;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .music_title span {
    font-size: 64px;
    font-family: 'Conv_Campton-Light';
    font-weight: lighter;
    color: #ffffff;
    padding-right: 50px; }

.primary_bg_section {
  background: #e54545; }

.search_field {
  padding-left: 0px;
  padding-top: 20px;
  position: relative;
  padding-bottom: 20px; }

.search_field input {
  font-size: 16px;
  font-family: 'Conv_Campton-MediumItalic';
  background: transparent;
  border: 1px solid #ffffff; }

.search_field .ui.form input[type="text"] {
  position: relative;
  color: #ffffff !important;
  font-size: 16px !important;
  font-family: 'Conv_Campton-MediumItalic' !important;
  width: 100%;
  height: 60px !important;
  border-radius: 0px !important;
  border: 1px solid #ffffff !important;
  background: transparent !important;
  text-indent: 15px; }

.search_field input::placeholder {
  color: #ffffff !important; }

.search_field .s_icon {
  position: absolute;
  right: 20px;
  top: 25px; }

.search_field .ui.form .fields {
  margin: 0px; }

.searchable_icon i {
  position: absolute;
  top: 25px;
  right: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: bolder; }

.music_list {
  position: relative;
  padding-bottom: 30px; }
  .music_list .play_icon i {
    position: absolute;
    left: 30px;
    top: 0;
    background: #e54545;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }

.user_name {
  color: #e54545;
  font-family: 'Conv_Campton-SemiBold';
  font-size: 16px;
  padding-right: 10px; }

.user_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 30px; }

i.icofont-heart.fav_heart {
  color: #952f2f;
  cursor: pointer;
  z-index: 1; }

.row.footer_menu {
  padding-top: 50px; }

.footer_nav {
  float: right; }
  .footer_nav ul {
    list-style: none;
    padding: 0px; }
  .footer_nav ul li {
    display: inline-block; }
  .footer_nav ul li a {
    font-family: 'Conv_Campton-Light';
    font-size: 19px;
    color: #ffffff !important;
    padding-left: 10px;
    letter-spacing: 0px;
    text-transform: uppercase; }
  .footer_nav .vertical_line {
    color: #ffffff; }

.capital {
  text-transform: capitalize; }

.event_data {
  background: #cccccc;
  width: 100%;
  padding: 40px;
  margin-top: 100px; }
  .event_data h6 {
    background: #e54545;
    color: #ffffff !important;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 15px; }
  .event_data p {
    padding-top: 20px;
    font-size: 18px;
    font-family: 'Conv_Campton-Light';
    color: rgba(0, 0, 0, 0.85); }

.events_date_div {
  background: #ffffff;
  padding: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 2px; }
  .events_date_div .event_date {
    position: relative; }
    .events_date_div .event_date i {
      font-size: 19px;
      vertical-align: baseline; }
    .events_date_div .event_date span {
      font-family: 'Conv_Campton-SemiBold';
      font-size: 24px;
      color: #212529;
      padding-left: 10px; }
    .events_date_div .event_date .event_day {
      display: inline-block; }
      .events_date_div .event_date .event_day .start_text {
        padding-left: 10px;
        font-family: 'Conv_Campton-SemiBold';
        font-size: 16px;
        color: #777777; }

.events_time_div {
  background: #ffffff;
  padding: 25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 2px; }
  .events_time_div .event_times {
    position: relative; }
    .events_time_div .event_times i {
      font-size: 19px;
      vertical-align: baseline; }
    .events_time_div .event_times span {
      font-family: 'Conv_Campton-SemiBold';
      font-size: 24px;
      color: #212529;
      padding-left: 10px; }
    .events_time_div .event_times .event_start {
      display: inline-block; }
      .events_time_div .event_times .event_start .start_text {
        padding-left: 10px;
        font-family: 'Conv_Campton-SemiBold';
        font-size: 16px;
        color: #777777;
        padding-right: 70px; }

.event_location {
  background: #ffffff;
  padding: 25px;
  margin-top: 30px;
  border-radius: 10px;
  display: inline-flex;
  width: 100%; }
  .event_location img {
    max-width: 50px; }
  .event_location .event_info {
    padding-left: 20px;
    font-family: 'Conv_Campton-SemiBold';
    font-size: 19px;
    color: #212529; }

.event_details {
  font-family: 'Conv_Campton-Light';
  font-size: 24px;
  color: #212529;
  margin-bottom: 10px; }

h6 {
  background: #e54545;
  color: #ffffff !important;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 15px; }

.video_player {
  padding-top: 40px; }

.plist_item {
  border: 5px solid #e54545;
  margin-bottom: 30px; }
  .plist_item .item_desc {
    text-align: center; }
    .plist_item .item_desc img {
      height: 250px; }
  .plist_item .desc {
    text-align: left;
    padding: 10px;
    font-family: 'Conv_Campton-Light'; }
  .plist_item .item_price {
    background-color: #e54545;
    padding: 10px;
    text-align: left;
    position: relative;
    font-family: 'Conv_Campton-Light';
    font-size: 24px;
    width: 101%;
    margin: 0 0 20px; }
    .plist_item .item_price span {
      font-family: 'Conv_Campton-Bold'; }
  .plist_item .cartbtn {
    width: 101%; }
    .plist_item .cartbtn .icon {
      margin-right: 20px; }

.cart_table .table-dark {
  text-align: center;
  font-family: 'Conv_Campton-Bold';
  font-size: 14px; }
  .cart_table .table-dark img {
    height: 40px;
    margin-right: 10px; }
  .cart_table .table-dark .quantity {
    border: 1px solid #ccc;
    padding: 2px 20px;
    margin: 0 10px; }
  .cart_table .table-dark .cross {
    color: red;
    margin-right: 10px; }
  .cart_table .table-dark .checkOut_qty {
    font-family: 'Conv_Campton-Bold';
    letter-spacing: 2px; }

.cartbtn {
  width: 100%;
  font-family: 'Conv_Campton-Bold'; }
  .cartbtn .icon {
    margin-right: 20px; }

.cart_table .table-dark tbody {
  text-align: center;
  font-family: 'Conv_Campton-Light'; }

.total {
  font-family: 'Conv_Campton-Bold';
  font-size: 24px; }

.bg_total {
  background-color: rgba(255, 255, 255, 0.05); }

.product_item {
  background: #ffffff;
  border: 1px solid #cccccc;
  padding: 30px 30px 80px 30px;
  margin-bottom: 40px; }
  .product_item ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .product_item ul li {
      padding-bottom: 20px; }
      .product_item ul li h2 {
        color: #e54545 !important;
        font-size: 19px; }
      .product_item ul li img {
        width: auto;
        height: 300px; }
  .product_item hr {
    background: #e0dede;
    margin-top: 5px; }
  .product_item .add_to_cart {
    position: absolute;
    bottom: 60px; }
    .product_item .add_to_cart span {
      padding-left: 10px; }
    .product_item .add_to_cart button {
      background-color: #e54545 !important;
      padding: 10px; }

.product_item_image {
  text-align: center; }

.product_item_details {
  font-family: 'Conv_Campton-Light'; }
  .product_item_details .item_price {
    background-color: #e54545;
    padding: 5px 10px;
    color: white;
    width: 50%;
    text-align: left;
    position: relative;
    margin-left: -10px;
    font-family: 'Conv_Campton-Light';
    font-size: 24px; }
    .product_item_details .item_price span {
      font-family: 'Conv_Campton-Bold'; }
  .product_item_details .item_desc {
    padding: 20px 0;
    color: white; }
  .product_item_details h2 {
    font-size: 42px;
    font-family: 'Conv_Campton-SemiBold'; }
  .product_item_details h5 {
    padding-bottom: 10px; }

.text_color {
  color: #ffffff; }

.card {
  border: none; }

.card-header {
  background: #e54545;
  padding: 20px;
  font-family: 'Conv_Campton-Light';
  font-size: 19px;
  color: #fff; }

.select_field {
  width: 100%;
  height: 60px;
  color: #191919;
  font-size: 19px;
  font-family: 'Conv_Campton-MediumItalic';
  text-indent: 15px; }

.no_data {
  font-family: 'Conv_Campton-Light';
  font-size: 19px;
  color: #fff;
  padding-left: 20px; }

.podcast_list {
  margin-bottom: 40px; }
  .podcast_list .pod_pic {
    position: relative; }
    .podcast_list .pod_pic img {
      width: 100%; }
    .podcast_list .pod_pic .play_icon i {
      position: absolute;
      left: 30px;
      top: 0;
      background: #e54545;
      color: #fff;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center; }
  .podcast_list .pod_details {
    background: #fff;
    padding: 15px; }
    .podcast_list .pod_details h4 {
      font-size: 19px; }
    .podcast_list .pod_details h5 {
      margin: 0;
      padding-bottom: 20px;
      font-size: 16px; }
    .podcast_list .pod_details h6 {
      margin-bottom: 20px; }
    .podcast_list .pod_details .pod_published p {
      display: inline-block;
      color: #e54545; }
    .podcast_list .pod_details .pod_duration p {
      display: inline-block;
      color: #e54545; }

.bag--float-cart-closed {
  background-color: #e54545; }

.float-close-btn {
  background-color: #e54545; }

.continue-btn {
  background-color: #e54545; }

.checkout_form {
  background: #ffffff;
  padding: 50px; }
  .checkout_form h1 {
    color: #e54545;
    font-family: 'Conv_Campton-SemiBold';
    font-size: 48px;
    padding-bottom: 50px; }
  .checkout_form .input_field {
    border-radius: 0px;
    background: #3d393e;
    opacity: 1;
    height: 60px;
    color: #eaeaea;
    font-family: 'Conv_Campton-MediumItalic';
    margin-bottom: 40px;
    text-align: left;
    text-indent: 20px; }

.product_main_div {
  max-height: 500px;
  overflow-y: auto; }

.prod_details {
  background: #ffffff;
  padding: 20px; }
  .prod_details h2 {
    color: #e54545;
    font-family: 'Conv_Campton-SemiBold'; }
  .prod_details .prod_item {
    border-bottom: 2px solid #e54545;
    padding-bottom: 10px;
    padding-top: 10px; }
    .prod_details .prod_item .item_details {
      position: relative; }
      .prod_details .prod_item .item_details span {
        color: #e54545; }

.total_products {
  background: #ffffff;
  padding: 20px;
  margin-top: 30px; }
  .total_products h2 {
    color: #e54545;
    font-family: 'Conv_Campton-SemiBold'; }
  .total_products .prod_item .item_details {
    font-size: 19px; }
    .total_products .prod_item .item_details span {
      color: #e54545; }

.subscribe_form {
  padding: 0px;
  text-align: center; }
  .subscribe_form h1 {
    color: #eaeaea;
    font-family: 'Conv_Campton-Light';
    font-size: 48px;
    padding-bottom: 50px; }
  .subscribe_form .input_field {
    border-radius: 0px;
    background: #3d393e;
    opacity: 1;
    height: 60px;
    color: #eaeaea;
    font-family: 'Conv_Campton-MediumItalic';
    margin-bottom: 40px;
    text-align: left;
    text-indent: 20px; }
  .subscribe_form .already_signup {
    color: #eaeaea;
    font-family: 'Conv_Campton-Light';
    font-size: 16px;
    padding-top: 40px; }
    .subscribe_form .already_signup span {
      color: #e54545; }

.ant-btn {
  background-color: #e54545 !important;
  border-color: #e54545 !important;
  color: #ffffff !important; }

.ant-btn-primary {
  background-color: #e54545 !important;
  border-color: #e54545 !important;
  color: #ffffff !important; }

.ant-modal-header {
  background-color: #e54545 !important; }
  .ant-modal-header .ant-modal-title {
    color: #ffffff !important; }

.ant-modal-footer {
  background-color: #e54545 !important;
  border-color: #e54545 !important; }

.submit_btn {
  width: 100%;
  text-align: center; }

.rodal-dialog {
  position: relative;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 100 !important; }

.rodal-dialog > div {
  position: relative;
  width: 100%;
  height: 100% !important; }

.rodal-close {
  right: 30px !important;
  top: 65px !important;
  width: 30px !important;
  height: 30px !important;
  background: #e54545;
  z-index: 99999 !important;
  opacity: 1; }

.rodal-close:before, .rodal-close:after {
  background: #ffffff !important; }

.video_modal .ant-modal-close {
  background: #e54545; }

.video_modal .ant-modal-content {
  position: relative;
  height: auto;
  min-height: 100% !important; }

.video_modal .ant-modal-body {
  padding: 0 !important;
  width: 100%;
  height: 100%; }

.video_modal .ant-modal-body > div {
  width: 100%;
  height: 800px !important;
  max-height: 1200px !important; }

.events_page {
  position: relative;
  margin-bottom: 30px; }

.events_page:hover .data_on_hover {
  display: block;
  background: #e54545;
  padding: 5px;
  position: absolute;
  top: 60px;
  left: 5%;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Conv_Campton-SemiBold'; }

.events_page .popular_slider_btn {
  left: 0;
  bottom: 60px; }

.slider_item {
  position: relative;
  cursor: pointer;
  padding-top: 20px; }

.search_form {
  position: relative; }

.search_form .row.form-group {
  padding-bottom: 0;
  margin: 0;
  padding-top: 30px; }

.search_form input.react-autosuggest__input {
  position: relative;
  width: 100%;
  color: #ffffff !important;
  font-size: 19px !important;
  font-family: 'Conv_Campton-MediumItalic' !important;
  height: 60px !important;
  border-radius: 0px !important;
  border: 1px solid #ffffff !important;
  background: #952f2f !important;
  text-indent: 15px; }

.search_form ul.react-autosuggest__suggestions-list {
  list-style: none;
  background: #ffffff;
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden; }

.video_upper_layer {
  width: 100%;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 98; }

.volume_icon {
  position: absolute;
  bottom: 75px;
  right: 90px;
  z-index: 99;
  padding: 5px;
  color: #ffffff;
  font-size: 19px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 40px;
  text-align: center; }

.banner_slider {
  width: 100% !important;
  height: 800px !important; }

.container {
  max-width: 95%; }

.notfound {
  text-align: center;
  font-family: 'Conv_Campton-SemiBold' !important;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px; }
  .notfound h1 {
    font-family: 'Conv_Campton-SemiBold' !important;
    font-size: 92px;
    margin-bottom: 0px;
    color: #ffffff !important; }
  .notfound h3 {
    font-family: 'Conv_Campton-SemiBold' !important;
    color: #ffffff !important; }
  .notfound p {
    color: #ffffff !important; }

.ad_image {
  text-align: center;
  margin-top: 20px; }

.no_video {
  display: block;
  margin: 0 auto; }

.start_end_date span {
  display: block; }

.trialLink {
  text-transform: uppercase;
  font-family: 'Conv_Campton-SemiBold';
  color: #ffffff !important;
  cursor: pointer;
  font-size: 14px;
  padding-left: 5px;
  letter-spacing: 1px;
  list-style: none;
  padding: 0.5rem 1rem; }

.trialLink:hover {
  color: #ffffff !important; }

#clickmeetingFlashroomIframe {
  margin: 0 auto;
  width: 100%; }

/***************************************************** My Responsive ******************************************************/
@media only screen and (max-width: 768px) {
  .trial_formwrapper {
    width: 100%; }
  .prelogin_iconbox {
    margin-bottom: 10px; }
  .title_two {
    font-size: 22px !important; }
  .form-group label {
    font-size: 10px !important; }
  .footer_nav ul li a {
    font-size: 12px !important; }
  .header_row .col-sm-6 {
    width: 50% !important; }
  .navbar-light .navbar-toggler {
    margin-top: 20px;
    float: right; }
  .header_right_side {
    float: right; }
  .prelogin_bg {
    padding-top: 50px;
    padding-bottom: 10px; }
  .chapter_section {
    padding: 50px 20px; }
  .order_btn {
    font-size: 12px;
    padding: 9px 50px !important; }
  .purchase_btn {
    font-size: 12px;
    letter-spacing: 4.0px; }
  .theshift_title {
    font-size: 24px; }
  .theshift_subtitle {
    font-size: 12px; }
  .about_redtitle {
    font-size: 16px; }
  .btn_section {
    margin-bottom: 20px; } }

/*ipad pro potrait*/
@media (min-width: 1024px) and (max-width: 1366px) {
  .btn {
    padding: 9px 10px !important; }
  .banner_slider {
    height: 577px !important; }
  .video_upper_layer {
    height: 577px; }
  .bg-light .navbar-nav .nav-link {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px; }
  .header_row {
    margin: 0; }
  .it_crushed_me {
    padding: 7px; }
  .it_crushed_me h1 {
    font-size: 40px; }
  .it_crushed_me h2 {
    font-size: 24px; }
  .it_crushed_me h3 {
    padding-bottom: 0px; }
  .heart_icon i {
    right: 45px; }
  .latest_events .event_content {
    height: 270px; }
  .latest_events .event_content h3 {
    font-size: 22px; } }

/*ipad pro landscape*/
@media (min-width: 1366px) {
  .slider_text_btns {
    left: 15%; }
  .rodal-dialog {
    position: relative;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 100 !important; }
  .rodal-dialog > div {
    position: relative;
    width: 100%;
    height: 100% !important; }
  .rodal-close {
    right: 30px !important;
    top: 65px !important;
    width: 30px !important;
    height: 30px !important;
    background: #e54545;
    z-index: 99999 !important;
    opacity: 1; }
  .latest_events .event_content {
    height: 240px; } }

/*ipad potrait*/
@media (min-width: 768px) and (max-width: 1023px) {
  .btn {
    padding: 9px 10px !important; }
  .banner_slider {
    height: 430px !important; }
  .video_upper_layer {
    height: 430px; }
  .resp_no_padding {
    padding-left: 0px;
    padding-right: 0px; }
  .navbar-light .navbar-toggler {
    margin-top: 20px; }
  .it_crushed_me {
    padding: 0px; }
    .it_crushed_me h1 {
      font-size: 36px;
      padding-bottom: 0px; }
    .it_crushed_me h2 {
      padding-bottom: 0px; }
    .it_crushed_me h3 {
      padding-bottom: 0px; }
  .it_crushed_me .it_crushed_btns {
    margin-top: 15px; }
  .it_crushed_me .it_crushed_btns button {
    margin-right: 0px; }
  .subscribe_prime .subscribe_prime_text {
    font-size: 24px; }
  .footer_nav ul li a {
    font-size: 16px;
    padding-left: 5px; }
  span.vertical_line {
    padding-left: 5px; }
  .modal-video-close-btn {
    right: 0px !important; }
  .latest_events .event_content {
    padding: 15px;
    height: 270px; }
  .latest_events .event_content h3 {
    font-size: 18px; }
  .latest_events .event_content .arrow_view_more {
    bottom: 25px; }
  .popular_slick_slider .popular_slider_btn {
    font-size: 11px; } }

/*ipad landscape*/
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .latest_events .event_content {
    height: 300px; }
  .latest_events .event_content .arrow_view_more {
    bottom: 25px; }
  .popular_slick_slider .popular_slider_btn {
    font-size: 11px; } }

/*Mobile devices landscape*/
@media (min-width: 520px) and (max-width: 736px) {
  .banner_slider {
    height: 414px !important; }
  .video_upper_layer {
    height: 414px; }
  .volume_icon {
    right: 55px; }
  .container {
    padding-right: 15px !important;
    padding-left: 15px !important; }
  .btn {
    padding: 9px 10px !important; }
  .bg-light .navbar-brand img {
    height: 40px; }
  .bg-light .navbar-toggler {
    margin-top: 20px; }
  .navbar-brand {
    margin-right: 0; }
  .latest_events .event_header .event_red_side {
    font-size: 30px; }
  .latest_events .event_header .event_black_side {
    font-size: 30px; }
  .latest_events .event_content {
    padding: 15px; }
  .latest_events .event_content h3 {
    font-size: 16px; }
  .latest_events .event_content p {
    font-size: 13px; }
  .subscribe_prime .subscribe_prime_text {
    font-size: 20px; }
  .latest_events .event_content .arrow_view_more span {
    padding-left: 0px;
    letter-spacing: 0px; }
  .row.footer_menu {
    text-align: center; }
  .top_footer {
    text-align: center; }
  .footer_nav {
    padding-top: 20px; }
  .popular_slick_slider .popular_slider_btn {
    font-size: 11px; }
  .music_back .music_title {
    padding: 30px 10px; }
  .music_back .music_title span {
    font-size: 36px; } }

/*Mobile devices potrait*/
@media (min-width: 320px) and (max-width: 731px) {
  .banner_slider {
    height: 233px !important; }
  .video_upper_layer {
    height: 233px; }
  .volume_icon {
    bottom: 25px;
    right: 30px; }
  .resp_no_padding {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .margin_bottom {
    margin-bottom: 0; }
  .container {
    padding-right: 32px;
    padding-left: 40px; }
  .btn {
    padding: 9px 10px !important; }
  .header_row {
    text-align: center; }
  .bg-light .navbar-brand {
    padding-bottom: 20px; }
  .header_right_side {
    text-align: center; }
  .modal-video-close-btn {
    right: 0px !important; }
  .slider_text_btns {
    padding: 10px; }
  .slider_text_btns h3 {
    font-size: 13px;
    padding-bottom: 10px; }
  .slider_text_btns h1 {
    font-size: 24px; }
  .slider_text_btns .video_btn {
    margin-top: 20px; }
  .why_scout_slider .carousel.carousel-slider .control-arrow {
    width: 50px; }
  .it_crushed_me {
    padding: 10px; }
  .it_crushed_me h1 {
    font-size: 30px;
    padding-bottom: 10px;
    letter-spacing: 1px; }
  .it_crushed_me h2 {
    font-size: 19px; }
  .it_crushed_me h3 {
    font-size: 19px;
    padding-bottom: 10px; }
  .it_crushed_me .it_crushed_btns {
    margin-top: 20px; }
  .it_crushed_me .it_crushed_btns button {
    margin-right: 0px; }
  .subscribe_prime .subscribe_prime_text {
    font-size: 24px; }
  .sub_mail {
    width: 80px; }
  .row.footer_menu {
    text-align: center; }
  .top_footer {
    text-align: center; }
  .footer_nav {
    padding-top: 20px; }
  .copyright-area p {
    font-size: 13px; }
  .page_bg .signup_form {
    width: 320px;
    padding: 20px; }
  .upload_profile_pic {
    padding: 10px; }
  .popular_slick_slider .popular_slider_btn {
    font-size: 11px; }
  .music_back .music_title {
    padding: 30px; }
  .music_back .music_title span {
    font-size: 24px;
    padding-right: 40px; }
  .album_details {
    letter-spacing: 0px; }
  .album_details h1 {
    font-size: 36px; }
  .already_signup {
    font-size: 14px !important; }
  .latest_events .event_content {
    height: 290px; } }

@media (min-width: 320px) and (max-width: 568px) {
  .banner_slider {
    height: 180px !important; }
  .video_upper_layer {
    height: 180px; }
  .volume_icon {
    font-size: 14px;
    width: 30px; }
  .btn {
    font-size: 11px;
    padding: 9px 10px !important; }
  .section_title {
    font-size: 19px; }
  .slider_text_btns h1 {
    font-size: 19px; }
  .slider_text_btns h3 {
    font-size: 11px; }
  .it_crushed_me h1 {
    font-size: 24px; }
  .it_crushed_me h2 {
    font-size: 16px; }
  .it_crushed_me h3 {
    font-size: 16px; }
  .subscribe_prime .subscribe_prime_text {
    font-size: 14px; }
  .copyright-area p {
    font-size: 11px; }
  .latest_events .event_content {
    height: 320px; }
  .latest_events .event_content h3 {
    font-size: 22px; } }

/* Pixel 2 XL */
/* Landscape */
@media screen and (min-width: 412px) and (max-height: 823px) and (-webkit-device-pixel-ratio: 3.5) and (orientation: landscape) {
  .user_name {
    font-size: 13px;
    padding-right: 0; } }
